//======================================================================================================
// Filters
//======================================================================================================

    //===============================================
    // Filter Keyword - Button search / close (no results)
    //===============================================
    // Colors
    $maps-Keyword-close-color                   : $color-white;
    $maps-Keyword-close-color--hover            : $color-dark;

    // Backgrounds
    $maps-Keyword-close-bg                      : $color-dark;
    $maps-Keyword-close-bg--hover               : $filter-bg;

    // Borders
    $maps-Keyword-close-border-color            : $maps-Keyword-close-bg;
    $maps-Keyword-close-border-color--hover     : $maps-Keyword-close-border-color;


    //===============================================
    // Filter input (category)
    //===============================================
    $f-maps-input-font-size                     : $f-input-font-size;
    $f-maps-input-color                         : $f-input-color;
    $f-maps-input-bg                            : $f-input-bg;
    $f-maps-input-border-width                  : $f-input-border-width;
    $f-maps-input-border-color                  : $f-input-border-color;
    $f-maps-input-border-radius                 : $f-input-border-radius;
    $f-maps-input-icon                          : $f-input-icon;
    $f-maps-input-container-bg                  : transparent;

    // Active / is open
    $f-maps-input-border-color--selected        : $f-input-border-color--active;
    $f-maps-input-icon--selected                : $f-input-icon--active;

    // Checked filter + hover filter
    $f-maps-input-color--checked                : $color-white;
    $f-maps-input-bg--checked                   : $color-fourth;
    $f-maps-input-border-color--checked         : $color-fourth;
    $f-maps-input-icon--checked                 : $color-white;

    //===============================================
    // Sub Filter input (subcategory)
    //===============================================
    $f-maps-sub-input-color                     : $f-sub-input-color;
    $f-maps-sub-input-bg                        : $f-sub-input-bg;
    $f-maps-sub-input-border-width              : $f-sub-input-border-width;
    $f-maps-sub-input-border-radius             : $f-sub-input-border-radius;
    $f-maps-sub-input-border-color              : $f-sub-input-border-color;

    // Sub filter checked + hover filter
    $f-maps-sub-input-color--checked            : $f-sub-input-color--checked;
    $f-maps-sub-input-bg--checked               : $f-sub-input-bg--checked;
    $f-maps-sub-input-border-color--checked     : $f-sub-input-border-color--checked;
