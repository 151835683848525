.scrollTopBtn {
    @include fixed($right: 45px, $bottom: 65px);
    z-index: 100;
    @include size(50px);
    padding: 0;
    margin: 0;
    background-color: $color-white;
    border-radius: $border-radius--round;
    opacity: 0;
    transform: translateY(100px);
    transition: $duration;

    &:before {
        content: '';
        position: absolute;
        @include size(calc(100% + 2px));
        top: 0;
        left: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin: auto;
        border-radius: $border-radius--round;
        background: $gradient-main;
        opacity: 1;
        transition: $duration;
    } 

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
    }

    svg {
        fill: $color-white;
        z-index: 1;
        transition: fill $duration $timing;
    } 

    &:hover,
    &:focus {
        background: $color-white;
        border: 1px solid $color-main;

        svg {
            fill: $color-main;
        }

        &:before {
            opacity: 0;
        }
    }
}