.nav-main {
    position: relative;
    width: 100%;

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: center);
    }

    // Main menu
    .menu {
        @include flex($wrap: wrap);

        &__item {
            &.menu-item-has-children.js-open-submenu {
                .submenu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }

                .menu__link {
                    background-color: $color-bg--transparent;
                    color: $color-white;

                    &:after {
                        width: 80px;
                    }
                }
            }

            &:nth-child(1){
                margin-right: 150px;

                .menu__link {
                    &:after {
                        background: $gradient-main;
                    }
                }

                .submenu__link {
                    &:before {
                        background-color: $color-main;
                    }

                    &--grandchild {
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }

            &:nth-child(2){
                .menu__link {
                    &:after {
                        background: $gradient-second;
                    }
                }

                .submenu__link {
                    &:before {
                        background-color: $color-second;
                    }

                    &--grandchild {
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }

            &:nth-child(3){
                margin-left: 400px;

                .menu__link {
                    &:after {
                        background: $gradient-third;
                    }
                }

                .submenu__link {
                    &:before {
                        background-color: $color-third;
                    }

                    &--grandchild {
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            @include flex($alignItems: center);
            height: 120px;
            background-color: $color-bg--transparent;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            color: $color-text;
            line-height: toRem(20);
            text-transform: uppercase;
            z-index: 3;
            transition: {
                duration: $duration;
                timing-function: $timing;
            }
    
            &:after {
                content: '';
                @include absolute($bottom: 40px, $right: 0, $left:0);
                @include size(30px, 3px);
                margin: auto;
                max-width: 100%;
                transition: $duration;
            }
    
            &:hover, &:focus {
                background-color: $color-bg--transparent;
                color: $color-text;

                &:after {
                    width: 80px;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        @include flex($justifyContent: center);
        position: absolute;
        right: 0;
        z-index: 2;
        width: 100%;
        padding: 20px 0 80px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: {
            property: visibility, opacity, pointer-events;
            duration: $duration;
            timing-function: $timing;
        }

        &__nav {
            width: 100%;
            column-count: 3;
            column-gap: 30px;
        }

        &__item {
            display: inline-flex;
            width: 100%;
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            column-gap: 10px;
            width: 100%;
            min-height: 50px;
            height: auto;
            padding: 5px 15px 5px 0;
            color: $color-white;
            font-weight: $font-weight-black;
            line-height: toRem(25);
            transition: $duration;

            &::before {
                content: "";
                display: inline-block;
                @include size(8px);
                border-radius: $border-radius--round;
                background-color: $color-white;
                flex: none;
                transition: $duration;
            }

            &:hover, &:focus {
                padding: 5px 0 5px 15px;

                &::before {
                    background-color: $color-white!important;
                }
            }

            &--grandchild {
                display: flex;
                padding: 5px 0 5px 15px;
                font-weight: $font-weight;
                font-style: italic;

                &::before {
                    @include size(15px,1px);
                    background-color: $color-white;
                    border-radius: 0;
                }
            }
        }
        
        &__close {
            display: none;
            @include absolute($top: 13px);
            @extend %SpaceFull-to-Container--right;
            margin: 0;
            padding: 0;
            background-color: $color-bg--transparent;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &__icon {
                display: block;
                @include size(26px);
                margin-right: 7px;
                background-color: $color-main;
                border: $btn-border-width $btn-border-style $btn-border-color;
                border-radius: $border-radius--round;
                fill: $color-white;
            }

            &__mobile {
                display: none;
            }

            svg {
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                .submenu__close__icon {
                    background-color: $color-bg--transparent;
                    fill: $color-main;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        .menu {
            &__item {
                &:nth-child(1){
                    margin-right: 100px;
                    margin-left: 10px;
                }
    
                &:nth-child(3){
                    margin-left: 300px;
                }
            }

            &__link {
                font-size: $font-size--text-small;
                line-height: toRem(20);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-open-menu {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-open-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-fourth;
            padding: 40px 0;
            transition: left .35s $timing;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            row-gap: 5px;
            padding: 5px;
            margin: 5px 0 0;
            background: none!important;
            border: 0;
            border-radius: 0;
            font-family: $font-family--heading;
            font-size: $font-size--text;
            font-weight: $font-weight-semibold; 
            color: $color-text;
            line-height: toRem(20);
            text-transform: uppercase;
            cursor: pointer;
    
            &:after {
                content: '';
                display: block;
                @include size(30px, 3px);
                margin: 0 auto;
                background: $gradient-main;
                transition: $duration;
            }

            &:hover, &:focus {
                color: $color-text;
                background-color: $color-bg--transparent;
                border: none;
            }
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            min-height: 40px;
            margin: 0 40px 0 auto;
            padding: 10px;
            background-color: transparent;
            border: $btn-border-width $btn-border-style $color-white;
            border-radius: $border-radius--round;

            svg {
                width: 12px;
                height: 12px;
                margin: 0;
                fill: $color-white;
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 100%;
            padding: 0 40px;
            row-gap: 15px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
                
                &:nth-child(1) {
                    margin-right: 0;
                    margin-left: 0;
                }
                
                &:nth-child(3) {
                    margin-left: 0;
                }
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-white;
                        transition: none;
                    }

                    // If sub menu open
                    &.js-open-submenu {   
                        .submenu {
                            right: 0;
                        }
    
                        .menu__link {
                            background-color: $color-bg--transparent;
                            color: $color-white;
                        }
                    }
                }
    
                &:hover, &:focus {   
                    .menu__link {
                        background-color: $color-bg--transparent;
                        color: $color-white;
                    }
                }
            }

            &__link {
                flex-direction: row-reverse;
                justify-content: flex-end;
                column-gap: 10px;
                max-width: inherit;
                width: 100%;
                height: auto;
                padding: 20px 0;
                color: $color-white;
                transition: none;

                &::after {
                    display: block;
                    margin: 0;    
                    bottom: 10px;
                }

                &:hover, &:focus {
                    background-color: $color-bg--transparent;
                    color: $color-white;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 5;
            @include flex($direction: column, $justifyContent: flex-start);
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 40px 0 60px 0;
            background-color: $color-fourth;
            transition: {
                property: visibility, pointer-events, right;
                duration: .35s;
                timing-function: $timing;
            }
            opacity: 1;

            /* &::after {
                display: none;
            } */

            // Burger menu responsive - Close submenu
            &__close {
                position: static;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 40px;
                height: 40px;
                margin: 0 0 0 40px;
                padding: 0;
                background-color: transparent;
                border: $btn-border-width $btn-border-style $color-white;
                border-radius: $border-radius--round;
                order: 1;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: none;
                        stroke: $color-dark;
                    }
                }

                &__mobile {
                    display: block;
                    width: 40px;
                    height: 40px;
                    fill: none;
                    stroke: $color-white;
                    stroke-width: 2px;
                }

                &__icon, span {
                    display: none;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0 40px;
                overflow-y: auto;
                order: 2;
            }

            &__item {
                margin: auto; 
            }

            &__link {
                color: $color-white;

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }


}
