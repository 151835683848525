.page-pagination {
    text-align: center;
    margin: 0 0 30px 0;

    p {
        margin-top: 15px;
        margin-bottom: 10px;
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.4px;
        font-size: $font-size--text-small;
        color: $color-main;
        text-transform: uppercase;
    }
}

// For archive button see more post
button#load-older-posts {
    margin: 0;
    transition: {
        property: background-color, color, border-color, opacity;
        duration: $duration;
        timing-function: $timing;
    }

    &:hover, &:focus {
        color: $color-white;
    }
}


//======================================================================================================
// Responsive 
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .page-pagination {     
        margin-top: 15px;
    } 

} 
