.post_navigation {
    // @include flex($justifyContent: center);
    padding: 30px 0;
    background-color: $color-light;

    .navigation_link {
        @include flex($alignItems: center);
        text-transform: uppercase;
        font-family: $font-family--heading;
        font-size: $font-size--text-small;
        font-weight: $font-weight-semibold;
        letter-spacing: 1.4px;
        color: $color-text;
        transition: color $duration $timing;

        svg {
            @include size(26px);
            fill: $color-text;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            color: $color-fourth;

            svg {
                fill: $color-fourth;
            }
        }
    }
}

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        // Si les liens sont cachés, mettre ce style :
        // justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 127px;

        .navigation_link {
            color: $color-gray;

            // Recup style ~ ligne 9
            a {
                @include flex($alignItems: center);
                text-transform: uppercase;
                font-family: $font-family--heading;
                font-size: $font-size--text-small;
                font-weight: $font-weight-semibold;
                letter-spacing: 1.4px;
                color: $color-text;
                transition: color $duration $timing;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-gray;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
             display: flex;
            justify-content: flex-end;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-gray;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                color: $color-main;

                svg {
                    fill: $color-main;
                }

                a {
                    color: $color-fourth;
                    
                    &:before, &:after {
                        background-color: $color-fourth;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
        }

        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}