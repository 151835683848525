.footer {
    background-color: $color-fourth;
    padding: 95px 0 80px;

    &__title {
        font-family: $font-family--heading;
        font-size: $font-size--3;
        line-height: toRem(35);
        color: $color-white;
        font-weight: $font-weight-semibold;
        margin: 0 0 20px;

        span {
            display: block;
            text-transform: uppercase;
            font-size: $font-size--5;
            line-height: toRem(25);
            font-weight: $font-weight-light;
        }
    }

    &__text {
        color: $color-white;
        line-height: toRem(25);
    }

    &__infos {
        @include flex($alignItems: flex-end);
    }

    &__address {
        margin-right: 100px;
        width: 270px;
    }

    &__hours {
        margin-right: 130px;
        width: 400px;
    }

    &__phone {
        color: $color-white;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: $duration ease-in;

        &:hover, &:focus-within {
            color: $color-white;
            text-decoration-color: $color-white;
        }
    }

    &__buttons {
        @include flex($direction: column);
        row-gap: 20px;
    }

    &__rs {
        @include flex($alignItems: center);
        column-gap: 10px;

        &__link {
            margin: 0;
            padding: 0;
            border-color: $color-white;
            border-radius: $border-radius--round;
            background-color: $color-bg--transparent;
            @include size(40px);
            min-height: 0;
            transition: $duration;

            svg {
                fill: $color-white;
                @include size(100%);
                transition: $duration;
            }

            &:hover, &:focus-within {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-fourth;
                }
            }
        }
    }

    &__contact {
        margin: 0;
        padding: 0;
        border-color: $color-white;
        @include size(270px,50px);
        background-color: $color-bg--transparent;
        z-index: 1;

        &:before {
            content: '';
            border-radius: 10px;
            position: absolute;
            @include size(0,100%);
            left: 0;
            background-color: $color-white;
            transition: .35s ease-in-out;
            z-index: -1;
        }

        &:hover, &:focus-within {
            background-color: $color-bg--transparent;
            border-color: $color-white;
            color: $color-fourth;

            &::before {
                width: 100%;
            }
        }
    }

    /* Menu footer */
    &__menu {
        @include flex($alignItems: center);
        margin-top: 60px;

        &__item {
            @include flex($alignItems: center);
            flex: auto;

            &:after {
                content: '';
                display: inline-block;
                @include size(8px);
                border-radius: $border-radius--round;
                background-color: $color-white;
                margin: auto;
            }

            &:last-child {
                flex: none;
                &:after {
                    display: none;
                }
            }
        }

        &__link {
            color: $color-white;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: $duration ease-in;

            &:hover, &:focus-within {
                color: $color-white;
                text-decoration-color: $color-white;
            }
        }

    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        padding: 100px 0 80px;
    
        &__title {
            span {
                font-size: $font-size;
            }
        }

        &__text {
            font-size: $font-size--text-small;
        }

        &__phone {
            font-size: $font-size--text-small;
        }
    
        &__address {
            margin-right: 80px;
            width: 240px;
        }
    
        &__hours {
            margin-right: 70px;
            width: 350px;
        }
    
        &__contact {
            width: 200px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 145px 0 60px;

        &__infos {
            flex-wrap: wrap;
            row-gap: 35px;
        }

        &__text {
            max-width: 300px;
        }
    
        &__address {
            margin-right: 0;
            width: 50%;
            min-width: 240px;
        }
    
        &__hours {
            margin-right: 0;
            width: 50%;
            min-width: 295px;
        }
        
        &__buttons {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: flex-end;
            flex-wrap: wrap;
            row-gap: 30px;
        }

        &__rs {
            width: auto;
        }

        &__contact-wrapper {
            width: 50%;
            min-width: 250px;
        }

        &__menu {
            flex-wrap: wrap;
            row-gap: 15px;

            &__item {
                flex: none;

                &:after {
                    margin: auto 40px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .footer {
        &__menu {
            &__item {
                &:after {
                    margin: auto 30px;
                }
            }
        }
    }
}
