@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        text-shadow: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }


    //=================================================================================================
    // Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol
    //=================================================================================================
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        page-break-inside: avoid;
        border: 1px solid #999;
    }


    //=================================================================================================
    // Printing Tables : http://css-discuss.incutio.com/wiki/Printing_Tables
    //=================================================================================================
    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    table, td {
        border-color: $color-black !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    h1 {
        font-size: 24px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    h3 {
        font-size: 18px !important;
    }

    h4 {
        font-size: 16px !important;
    }

    .cover {
        margin-top: 0 !important;

        &__intro {
            font-size: 16px !important;
        }
    }

    button, .button {
        font-size: 14px !important;
    }

    header, .tags, .comments, .breadcrumb, .cover__image-wrapper, .actions-post, .post_navigation, footer, .contextual-publications {
        display: none !important;
    }
}
