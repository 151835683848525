@keyframes SubmenuBckDown {
    from {
        height: 0;
    }
    to {
        height: 420px
    }
}

.header {
    @include size(100%,120px);

    &__container {
        position: relative;
        @include size(100%,120px);
        @include flex($alignItems: center);
        z-index: 2;

        &:after {
            content: '';
            position: absolute;
            height: 0;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: calc(var(--vw, 1vw) * 100);
            background-color: $color-fourth;
            transition: .35s;
            z-index: 1;
        }
    }

    &.js-open-submenu {
        .header {
            &__container {
                &:after {
                    height: 370px;
                    transition: .35s;
                }
            }
        }

        .identity {
            &__logo {
                background-color: $color-white;

                &--main {
                    height: 0;

                    svg {
                        opacity: 0;
                    }
                }

                &--min {
                    display: flex;
                    height: 100px;

                    svg {
                        display: block;
                        fill: $color-text;

                        path {
                            fill: $color-text;
                        }
                    }
                }
            }
        }

        .tools {
            &__item {
                svg {
                    fill: $color-white;
                }
            }
        }

        .nav-main {
            .menu__link {
                color: $color-white;
            }
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
@keyframes logoBckDown {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

@keyframes logoSVGDown {
    from {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.identity {
    @include flex($alignItems: center, $justifyContent: center);
    /* @include size(190px,180px); */
    @include absolute($left: 0, $right: 0, $top: 0);
    margin: auto;
    transition: $duration $timing;

    &__title {
        margin: 0;
        font-size: 0;
        line-height: 0;

        &:after {
            display: none;
        }
    }

    &__logo {
        @include absolute($top: 0);
        align-items: center;
        justify-content: center;
        border-radius: 0 0 $border-radius $border-radius;
        background-color: $color-fourth;
        transition: .45s;
        transition-delay: .2s;

        /* big logo */
        &--main {
            display: flex;
            @include size(190px,180px);
            animation: 1s logoBckDown;
            z-index: 1;
    
            svg {
                @include size(100px,110px);
                animation: 1.5s logoSVGDown;
                transition: opacity .7s;
                opacity: 1;
                transition-delay: .5s;
            }
        }
    
        /* mini logo */
        &--min {
            animation: 1s logoBckDown;
            z-index: 2;
            @include size(190px,0);
            transition: .5s;
    
            svg {
                display: none;
                @include size(100px,45px);
                animation: 1.5s logoSVGDown;
                transition: .75s;
                transition-delay: .75s;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {
    @include absolute($right: 0);
    @include flex($alignItems: center);
    column-gap: 10px;

    &__item {
        position: relative;
        border-radius: $border-radius--round;
        @include flex($justifyContent: center, $alignItems: center);
        @include size(40px);
        margin: 0;
        padding: 0;
        cursor: pointer;
        z-index: 2;
        border: none;
        background-color: $color-bg--transparent;
        transition: $duration;

        &:before {
            background: linear-gradient(90deg,#96368b,#e6007e);
            border-radius: 50%;
            content: "";
            left: -1px;
            opacity: 0;
            position: absolute;
            transition: .3s ease-in-out;
            @include size(calc(100% + 1px));
            z-index: 1;
        }
      
        &:after {
            content: '';
            @include size(100%);
            @include absolute($top: 0, $left: 0);
            border-radius: $border-radius--round;
            border: 1px solid $color-bg--transparent;
            background: $gradient-main border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
        } 

        svg {
            @include size(40px);
            fill: $color-text;
            z-index: 2;
            transition: fill $duration;
        }

        &:hover, &:focus {
            background: $color-bg--transparent;

            svg {
                fill: $color-white;
            }

            &:before {
                opacity: 1;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }

}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    &__container {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background-color: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: padding-left $duration $timing;

                        &:hover, &:focus {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Header fixed
//======================================================================================================
@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}

.header--fixed {
    .header {
        &__container {
            position: fixed;
            animation: .5s headerFixed;
            z-index: 10;
            top: 0;
            margin: 0 auto;
            height: 70px;
            max-width: 1170px;
            transition: .35s;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 70px;
                width: calc(var(--vw, 1vw) * 100);;
                background-color: $color-white;
                border-bottom: 1px solid $color-light;
            }
        }
    }

    .identity {
        &__logo {
            &--main {
                height: 0;
                transition: none;

                svg {
                    height: 0;
                    opacity: 0;
                }
            }

            &--min {
                display: flex;
                height: 100px;
                transition: .35s;
                animation: 1s logoBckDown;

                svg {
                    display: block;
                }
            }
        }
    }

    .nav-main {
        .menu__link {
            height: 70px;

            &:after {
                bottom: 15px;
            }
        }

        .submenu {
            padding: 60px 0 80px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    
    .identity {
        &__logo {
            /* big logo */
            &--main {
                @include size(165px,155px);
        
                svg {
                    @include size(85px,95px);
                }
            }
        
            /* mini logo */
            &--min {
                @include size(165px,0);
        
                svg {
                    @include size(85px,45px);
                }
            }
        }
    }

    .header--fixed {
        .header {
            &__container {
                max-width: 940px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .tools-view {
        top: calc(100% + 5px);
    }

    .header {
        &__container {
            max-width: 535px;
            width: 100%;
            margin: auto;
            z-index: initial;

            &:after {
                display: none;
            }
        }
        


        &.js-open-submenu {
            .header {
                &__container {
                    &:after {
                        display: none;
                    }
                }
            }

            .identity {
                &__logo {
                    background-color: $color-fourth;

                    &--min {
                        display: none;

                        svg {
                            display: block;
                            fill: $color-text;

                            path {
                                fill: $color-text;
                            }
                        }
                    }
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {


    .tools-view {
        &__container {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

    .header {
        height: 80px;
        
        &__container {
            max-width: 425px;
            height: 80px
        }
    }

    .identity {
        &__logo {
            /* big logo */
            &--main {
                @include size(125px,120px);
        
                svg {
                    @include size(67px,75px);
                }
            }
        }
    }

    .tools {
        column-gap: 5px;

        &__item {
            @include size(35px);

            svg {
                @include size(35px);
            }
        }
    }

}
