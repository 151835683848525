.breadcrumb {    
    ul {
        display: block;
        min-height: 35px;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text;
        line-height: 1.75;

        a {
            color: $color-text;
            text-decoration: underline;
            text-decoration-color: transparent;
            transition: $duration ease-in;
            &:hover, &:focus {
                color: $color-text;
                text-decoration-color: $color-text;
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            fill: $color-main;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
