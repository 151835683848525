//======================================================================================================
// Bloc Contextual publication
//======================================================================================================
.contextual-publications {

    .grid-center {
        width: calc(100% + 30px);
    }

    .swiper-slide .card__container {
        &--event-v1 {
            min-height: 260px;
        }
        
    }
    
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .contextual-publications {    
        .swiper-slide .card__container {
            &--event-v1 {
                min-height: inherit;
            }
        }
    }

}