//=================================================================================================
// Table expands to full width of containing element
//================================================================================================= 
table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}


//=================================================================================================
// Extra border underneath header
//================================================================================================= 
thead th {
    border-bottom: 2px solid $border-color;
}


//=================================================================================================
// Extra border above footer
//================================================================================================= 
tfoot th {
    border-top: 2px solid $border-color;
}

td {
    border-bottom: 1px solid $border-color;
}

th,
td {
    text-align: left;
    padding: .5rem;
}

caption {
    color: $caption;
    caption-side: bottom;
    padding: 0;
}

.striped-table tbody tr:nth-child(odd) {
    background-color: $stripes;
}


//=================================================================================================
// Container for responsive tables
//================================================================================================= 
.contain-table {
    overflow-x: auto;
}
