//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .events-infos {
        .grid {
            .col_sm-12 {
                flex: 100%;
            }
        }
    }

}
