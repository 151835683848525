.cover {
    position: relative;
    background: rgba($color-dark, .1);
    padding: 60px 0 80px;

    &__image-wrapper {
        margin-top: 60px;
        width: 100%;
        height: 390px;
        border-radius: 20px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        padding: 30px 0 0;
        @include flex($direction: column);
    }

    &__title {
        margin: 0;
    }

    &__intro {
        color: $color-text;
        font-size: toRem(18);
        line-height: toRem(25);
        font-weight: $font-weight-semibold;
        margin-top: 20px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
