//======================================================================================================
// Post date
//======================================================================================================
.publication-date {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
    color: $color-date--single;

    svg {
        width: 26px;
        height: 26px;
        margin-right: 5px;
        fill: $color-date--single;
    }
}


//======================================================================================================
// Event
//======================================================================================================
.container {
    &--events {
        width: 100%;
    }
}

.events-infos {
    .bloc-map {
        margin: 0;
        height: 100%;
    }

    .wrapper_map {
        height: 100%;
    }

    #map {
        min-height: 550px;
    }
}


//======================================================================================================
// Date event
//======================================================================================================
.date-event {
    margin-top: 30px;
    
    &__title {
        color: $color-main;
        margin-bottom: 15px;
        margin-top: 0;
        padding: 0;
        border: 0;

        &::before {
            display: none;
        }
    }

    &__recurrence {
        color: $color-gray;
    }
}


//======================================================================================================
// Actions Event
//======================================================================================================
.actions-event {
    margin-top: 25px;
    margin-bottom: 40px;
    width: 100%;

    .actions-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        input[type=submit], .button {
            min-height: 60px;
            padding: $btn-padding;
        }
    }

    .button {
        svg {
            margin: 0 5px 0 -5px;
        }
    }
}


//======================================================================================================
// Tags
//======================================================================================================
.tags {
    margin: 60px 0 50px;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .actions-event {
        margin-top: 10px;
        margin-bottom: 20px;
    }

}


// 640
@media screen and (max-width: $small) {

    .card--event {
        .image-wrapper {
            width: 100%;
        }
    }

}
