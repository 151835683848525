//======================================================================================================
// Contact base
//======================================================================================================
// Component badge
.contact-badge {
    display: flex;
    align-items: center;

    &__icon {
        flex: none;
        width: 26px;
        height: 26px;
        margin-right: 8px;
        fill: $color-text;
    }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: underline;
        word-break: break-all;

        &:hover, &:focus {
            text-decoration: none;

            svg {
                fill: $color-link--hover;
            }
        }

        svg {
            width: 20px;
            height: 20px;
            fill: $color--card;
        }

        &--underlinenone {
            text-decoration: none;
        }
    }

    &__additional {
        margin-top: 10px;
        
        &--no-margin {
            margin-top: 0;
        }
    }

    &--icon-top {
        align-items: flex-start;

        .contact-badge__icon {
            margin-top: -2px; // fix for margin on svg
        }
    }

    &--secondary {
        padding: 7px 0;
        position: relative;
      
        &:after {
            content: '';
            @include size($btn-action-size);
            @include absolute($left: 0);
            border-radius: $border-radius--round;
            border: 1px solid $color-bg--transparent;
            background: $gradient-main border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
        }

        &:before {
            content: '';
            @include size($btn-action-size);
            @include absolute($left: 0);
            border-radius: $border-radius--round;
            background: $gradient-main;
            opacity: 0;
            transition: $duration;
        }

        .contact-badge__icon {
            box-sizing: content-box;
            @include size($btn-action-size);
            margin-right: 12px;
            fill: $btn-action-color;
            background-color: $color-bg--transparent;
            border: none;
            border-radius: $btn-action-border-radius;
            padding: 1px 0 0 0;
            z-index: 1;
            transition: {
                duration: $duration;
            }
        }

        &:hover, &:focus {   
            .contact-badge__icon {
                background: $gradient-main;
                fill: $color-white;
            }

            &:before {
                opacity: 1;
            }
        }
    }
}


//======================================================================================================
// Contact Single
//======================================================================================================
.contact-single { 

    // Container
    .contact-container {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}
