//======================================================================================================
// Bloc Colonnes
//======================================================================================================
.bloc-columns {
    display: flex;

    &--center {
        align-items: center;
    }    

    &__first,
    &__second {
        flex: 1;
    }

    &__first {
        padding-right: 15px;
    }
    
    &__second {
        padding-left: 15px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .bloc-columns {
        display: block;
    
        &__first {
            padding-right: 0;
        }
        
        &__second {
            padding-left: 0;
        }
    }
}
