//======================================================================================================
// Actions pages
//======================================================================================================
@keyframes show-hide {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.actions-post {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    li {
        position: relative;

        a, 
        button,
        div {
            display: flex;
            align-items: center;
            column-gap: 10px;
            height: 60px;
            margin: 0;
            padding: 0 30px;
            background-color: transparent;
            border: none;
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            font-weight: $font-weight-semibold;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            color: $color-text;
            cursor: pointer;
            transition: {
                duration: $duration;
                timing-function: $timing;
            }

            svg {
                @include size(26px);
                fill: $color-text;
                transition: fill $duration $timing;
            }

            &:hover,
            &:focus {
                color: $color-fourth;
                background-color: transparent;

                svg {
                    fill: $color-fourth;
                }
            }

            &.action-share {
                background-color: transparent;
                
                transition: {
                    duration: $duration, 0s;
                    timing-function: $timing;
                } 
            }
        }
    }

    .share-list {
        @include absolute($top: 10px, $left: 30px);
        display: none;
    }

    &.shared-on {
        .action-share {
            opacity: 0;
        }

        .share-list {
            display: flex;
            column-gap: 10px;

            li {
                border-right: 0;
            }

            a, 
            button,
            div {
                @include flex($justifyContent: center, $alignItems: center);
                @include size(40px);
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius--round;
                box-shadow: $shadow;
                padding: 0;
                opacity: 1;
                cursor: pointer;
                transition: background-color $duration $timing;

                svg {
                    margin: 0;
                    transform: scale(1.3);
                    transition: fill $duration $timing;
                }

                &.close {
                    background-color: $color-bg--neutral;

                    svg {
                        // transform: scale(0.8);
                        fill: $color-main;
                        // stroke: $color-main;
                        transform: scale(1.5);
                        transition: {
                            property: fill;
                            duration: $duration;
                            timing-function: $timing;
                        }
            
                    }

                    &:hover, 
                    &:focus {
                        background-color: $color-main;

                        svg {
                            fill: $color-white;
                            // stroke: $color-white;
                        }
                    }
                }

                #post_url {
                    position: absolute;
                    opacity: 0;
                }

                &:hover, 
                &:focus {
                    background-color: $color-white;
                    border: 0;

                    svg {
                        fill: $color-main;
                    }
                }
            }

            .copy-confirm {
                width: 105px;
                height: 30px;
                position: absolute;
                top: 35px;
                left: 50%;
                transform: translate(-50%, 0);
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                color: $color-white;
                text-transform: none;
                background-color: $color-gray;
                opacity: 0;
                box-sizing: border-box;

                &.show-hide {
                    animation : show-hide 2s;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

    // 960
    @media screen and (max-width: $medium) {
        .actions-post {
            &.shared-on {
                margin: 60px auto 20px;

                .action-share {
                    opacity: 1;
                }

                .share-list {
                    top: -50px;
                    left: -120px;
                    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15));
                }
            }
        }

    }



    // 640
    @media screen and (max-width: $small) {
        .actions-post {
            column-gap: 30px;
            margin: 20px auto;

            > li {
                border-right: 0;

                > a, 
                > button,
                > div {
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    background-color: $color-main;
                    border-radius: $border-radius--round;
                    padding: 0;

                    span {
                        display: none;
                    }

                    svg {
                        fill: $color-white;
                    }

                    &:hover, 
                    &:focus, 
                    &:active {
                        background-color: $color-fourth;

                        svg {
                            fill: $color-white;
                        }
                    }
                }
            }

            &.shared-on {
                .share-list {
                    left: -195px;

                    /* button,
                    div {
                        svg {
                            fill: $color-main;
                        }
                    } */
                }
            }
        }
    }