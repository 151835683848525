.home {
    &__empty {
        display: none
    }

    &__title {
        @extend h1;
        margin: 0 auto;
        text-transform: lowercase;
        @include flex($direction: column, $alignItems: center);

        &:after {
            margin-top: 5px;
        }
    }

    &__btn {
        color: $color-dark;

        &:after {
            content: '';
            @include size(100%);
            @include absolute($top: 0, $left: 0);
            border-radius: 10px;
            border: 1px solid $color-bg--transparent;
            z-index: -2;
        }

        &:before {
            content: '';
            border-radius: 10px;
            position: absolute;
            @include size(0,calc(100% + 2px));
            left: -1px;
            background-color: $color-fourth;
            transition: .35s ease-in-out;
            z-index: -1;
        }
        
        &:hover, &:focus-within {
            &:before {
                width: calc(100% + 2px);
            }
        }
    }

    &__nav {
        width: 90px;
        height: 40px;
        @include flex($alignItems: center, $justifyContent: space-between);
    }

    .swiper-button {
        padding: 0;
        border: none;
        background-color: $color-bg--transparent;
        border-radius: $border-radius--round;
        margin: 0;
        z-index: 1;
        position: relative;
        top: auto;
        @include size(40px);
        min-height: 0;
        transition: $duration;

        &:after {
            appearance: none;
        }

        &:before {
            content: '';
            @include size(100%);
            @include absolute($top: 0, $left: 0);
            border-radius: $border-radius--round;
            border: 1px solid $color-bg--transparent; 
            z-index: -2;
            background: $gradient-second border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            opacity: 1;
            transition: $duration;
        }

        &:after {
            content: '';
            @include size(100%);
            @include absolute($top: 0, $left: 0);
            border-radius: $border-radius--round;
            background: $gradient-second;
            opacity: 0;
            transition: $duration;
        }

        &:hover, &:focus-within {

            svg {
                fill: $color-dark;
            }

            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }

        svg {
            position: relative;
            @include size(40px);
            fill: $color-dark;
            flex: none;
            z-index: 1;
            transition: $duration;
        }

        &-next {
            right: auto;
        }

        &-prev {
            left: auto;
        }

    }

    //======================================================================================================
    // Flash info
    //======================================================================================================
    .newsthread {
        position: relative;
        z-index: 10;
        background-color: $color-white;
        box-shadow: $shadow;

        &__container {
            height: 60px;
            @include flex($alignItems: center);
            column-gap: 25px;
        }

        &__title {
            position: relative;
            height: 100%; 
            padding-right: 40px;
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            letter-spacing: 1px;
            text-shadow: 0px 0px 10px rgba(21, 19, 19, 0.05);
            font-size: toRem(20);
            line-height: toRem(30);
            color: $color-white;
            text-transform: uppercase;
            @include flex($alignItems: center);
            column-gap: 10px;
            z-index: 1;

            &:before {
                content: '';
                background-color: $color-dark;
                border-radius: 0 10px 10px 0;
                @include absolute($top: 0, $right: 0);
                z-index: -1;
                height: 100%;
                width: calc(((var(--vw, 1vw) * 100) - ($large - $gl-gutter))/2 + 100%);
            }

            svg {
                fill: $color-white;
                @include size(20px);
            }
        }

        &__link {
            @include flex($alignItems: center);
            height: 100%;
        }

        &__description {
            text-shadow: 0px 0px 10px rgba(21, 19, 19, 0.05);
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        background: rgba($color-dark, .1);
        padding: 120px 0 100px;

        &__list {
            @include flex($justifyContent: space-between);
            column-gap: 30px;
        }

        &__wrapper {
            flex: 1;
            height: 250px;

            &:nth-child(1){
                margin-top: 40px;
            }
            &:nth-child(2){
                margin-top: 0;
            }
            &:nth-child(3){
                margin-top: 40px;
            }
            &:nth-child(4){
                margin-top: 70px;
            }
            &:nth-child(5){
                margin-top: 20px;
            }
            &:nth-child(6){
                margin-top: 50px;
            }
        }

        &__item {
            @include flex($alignItems: center, $justifyContent: center, $direction: column);
            @include size(100%);
            border-radius: $border-radius;
            overflow: hidden;
            position: relative;
            padding: 5px;

            &:hover, &:focus-within {
                .access {
                    &__image {
                        &:before {
                            opacity: 0;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }

                    &__text {
                        &:after {
                            background: $gradient-main;
                            @include size(40px,3px);
                            margin: 40px auto 0;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        &__image {
            @include absolute($top: 0, $left: 0);
            @include size(100%);

            &:before, 
            &:after {
                content: '';
                @include absolute($top: 0, $left: 0);
                @include size(100%);
                transition: $duration;
            }

            &:before {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%);
                opacity: 1;
            }

            &:after {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
                opacity: 0;
            }

            img {
                object-fit: cover;
                @include size(100%);
            }
        }

        &__text {
            z-index: 1;
            color: $color-white;
            font-size: toRem(15);
            line-height: toRem(20);
            font-weight: $font-weight-semibold;
            text-align: center;
            text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.50);
            text-transform: uppercase;

            span {
                font-weight: $font-weight-light;
                display: block;
            }

            &:after {
                content: '';
                display: block;
                @include size(20px, 3px);
                background-color: $color-white;
                margin: 20px auto -5px;
                transition: margin .3s linear, width .3s ease-in, transform .35s ease-in;
            }
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        padding-top: 80px;

        &__title {
            &:after {
                background: $gradient-second;
            }
        }

        &__list {
            position: relative;
            margin-top: 40px;
        }

        .swiper-slide {
            width: 370px;
            margin-right: 30px;
        }

        &__item {
            @include flex($direction: column);
            row-gap: 10px;
            width: 100%;
            
            &:hover, &:focus-within {
                .posts {
                    &__item {
                        &__container-img {
                            img {
                                transform: scale(1.15);
                            }
                        }

                        &__title {
                            &:after {
                                width: 40px;
                            }
                        }
                    }
                }
            }

            &__container-img {
                border-radius: $border-radius;
                @include size(100%,250px);
                overflow: hidden;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) rgba($color-dark, .1) no-repeat center;

                img {
                    @include size(100%);
                    object-fit: cover;
                    transform: scale(1.0);
                    transition: .5s;
                }
            }

            &__title {
                color: $color-text;
                font-size: $font-size--4;
                line-height: torem(25);
                margin: 0;

                &:after {
                    content: '';
                    display: block;
                    background: $gradient-second;
                    margin-top: 10px;
                    @include size(0,3px);
                    transition: .35s ease-in-out;
                }
            }

            &__taxo {
                @include flex($alignItems: center);
                column-gap: 10px;
                text-transform: uppercase;
                font-size: $font-size--5;
                line-height: toRem(25);
                font-weight: $font-weight-light;
                font-family: $font-family--heading;

                &:before {
                    content: '';
                    display: inline-block;
                    background: $color-second;
                    @include size(10px);
                    border-radius: $border-radius--round;
                }
            }
        }

        &__nav {
            position: absolute;
            top: -80px;
            right: 0;
        }

        &__template {
            margin-top: 35px;
            @include flex($alignItems: center, $justifyContent: center);

            &__btn {
                background-color: $color-bg--transparent;
                color: $color-white;
                width: 270px;
                margin: 0;
                padding: 0;
                border: none;

                &:after {
                    content: '';
                    border-radius: 10px;
                    position: absolute;
                    @include size(100%);
                    left: 0;
                    background-color: $color-dark;
                    z-index: -2;
                }

                &:before {
                    background: $gradient-second;
                }

                &:hover, &:focus-within {
                    background-color: $color-bg--transparent;
                    color: $color-dark;
                }
            }
        }
    }

    //======================================================================================================
    // Evènements
    //======================================================================================================
    .events {
        padding: 80px 0;

        &__title {
            &:after {
                background: $gradient-third;
            }
        }

        &__list {
            margin-top: 40px;
            @include flex($justifyContent: space-between);
            column-gap: 30px;
        }

        &__item {
            flex: 1;    
            display: flex;
            justify-content: center;

            &:nth-child(even){
                margin-top: 30px;
            }
        }

        &__link {
            position: relative;
            @include size(270px,205px);
            background-color: $color-white;
            border-radius: $border-radius;
            box-shadow: $shadow;
            padding: 40px 25px 45px;
            @include flex($direction: column, $justifyContent: center);
            row-gap: 15px;

            &:hover, &:focus-within {
                .events {
                    &__link {
                        &__title {
                            &:after {
                                @include size(40px,3px);
                                margin-top: 10px;
                            }
                        }
                    }
                }
            }

            &__date {
                @include flex($alignItems: center, $justifyContent: center);
                @include absolute($bottom: 0, $right: 25px);
                transform: translateY(50%);
                background: $gradient-third;
                height: 50px;
                padding: 0 15px;
                border-radius: $border-radius;
                font-family: $font-family--heading;
                color: $color-text;
                text-transform: uppercase;
                font-weight: $font-weight-black;
                line-height: toRem(20);
            }

            &__title {
                color: $color-text;
                font-size: $font-size--text-medium;
                line-height: toRem(25);
                margin: 0;

                &:after {
                    content: '';
                    display: block;
                    @include size(0);
                    background: $gradient-third;
                    margin-top: 0;
                    transition-duration: .25s, .25s, .25s;
                    transition-timing-function: linear,linear, ease-in;
                    transition-property: margin, height, width;
                    transition-delay: 0s, 0s, .15s;
                }
            }

            &__taxo {
                @include flex($alignItems: center);
                column-gap: 10px;
                text-transform: uppercase;
                font-size: $font-size--5;
                line-height: toRem(25);
                font-weight: $font-weight-light;
                font-family: $font-family--heading;

                &:before {
                    content: '';
                    display: inline-block;
                    background: $color-third;
                    @include size(10px);
                    border-radius: $border-radius--round;
                    flex: none;
                }
            }

            &__location {
                @include flex($alignItems: center);
                column-gap: 10px;
                font-style: italic;
                font-size: $font-size--text-small;
                line-height: toRem(20);

                svg {
                    @include size(15px);
                }
            }
        }

        &__template {
            @include flex($alignItems: center, $justifyContent: center);
            column-gap: 30px;
            margin-top: 75px;

            &__prop,
            &__all {
                width: 270px;
                padding: 0;
                margin: 0;

                &:before {
                  background: $gradient-third;
                }
            }

            &__prop {
                border: none;
                color: $color-dark;
                background-color: $color-bg--transparent;
              
                &:after {
                    background: $gradient-third border-box;
                    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: destination-out;
                    mask-composite: exclude;
                }
            }

            &__all {
                background-color: $color-bg--transparent;
                color: $color-white;
                border: none;

                &:after {
                    content: '';
                    border-radius: 10px;
                    position: absolute;
                    @include size(100%);
                    left: 0;
                    background-color: $color-dark;
                    z-index: -2;
                }

                &:hover, &:focus-within {
                    background-color: $color-bg--transparent;
                    color: $color-dark;
                }
            }
        }
    }

    //======================================================================================================
    // Map
    //======================================================================================================
    .map {
        padding: 80px 0;
        height: 780px;
        background: rgba($color-dark, .1);

        &__title {
            position: relative;
            align-items: flex-start;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                background: url(/wp-content/themes/cc-adour-madiran/assets/src/images/carte.svg) no-repeat;
                background-size: contain;
                @include size(115px);
            }
        }

        &__text {
            line-height: toRem(25);
            margin-top: 40px;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            position: relative;
        }

        &__svg-wrapper {
            @include size(560px,620px);
            position: absolute;
            left: 250px;

            svg {
                @include size(100%);

                .st0{
                    fill:$color-main;
                    stroke:$color-white;
                    stroke-miterlimit:10;
                    transition: $duration $timing;
                }

                a {
                    &:hover, &:focus-within {
                        .st0 {
                            fill: $color-dark;
                        }
                    }
                }

            }

        }
        
        &__tooltip {
            width: 150px;
            min-height: 40px;
            position: absolute;
            z-index: 2;
            display: none;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            font-weight: $font-weight-semibold;
            color: $color-text;
            text-transform: uppercase;
            background-color: $color-white;
            padding: 5px 10px;
            border-radius: 10px;
            pointer-events: none;

            &::after {
                content: '';
                position: absolute;
                bottom: -14px;
                @include size(18px,15px);
                background: url(/wp-content/themes/cc-adour-madiran/assets/src/images/sprite-images/shapes/pointe_carte.svg) no-repeat center;
                background-size: contain;
            }

            &.active {
                display: flex;
            }
        }


        &__list-numbers {
            @include flex($direction: column);
            row-gap: 45px;
            margin-top: 80px;
        }

        &__number {
            &__count,
            &__label {
                font-family: $font-family--heading;
                font-weight: $font-weight-black;
                text-transform: uppercase;
                text-align: center;
            }

            &__count {
                font-size: toRem(60);
                line-height: toRem(60);
            }

            &__label {
                font-size: toRem(25);
                line-height: 2.5rem;
            }
        }

        &__content {
            max-width: 360px;
            width: 100%;
            margin-right: 50px;
            margin-top: 100px;
        }

        &__btn {
            margin: 30px 0 0;
            width: 270px;
            border-color: $gradient-main;
            border: none;
            color: $color-dark;
            background-color: $color-bg--transparent;
          
            &:after {
                background: $gradient-main border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                mask-composite: exclude;
            }

            &:before {
                background: $gradient-main;
            }

            &:hover, &:focus-within {
                color: $color-white;
            }
        }
    }

    //======================================================================================================
    // Follow
    //======================================================================================================
    .follow {
        padding: 80px 0;

        &__container {
            @include flex($justifyContent: space-between);
            margin-top: 40px;
        }

        &__title {
            &:after {
                background: $gradient-third;
            }
        }

        &__sw {
            flex: 1;
        }

        &__content {
            @include size(370px);
            background-color: $color-dark;
            border-radius: $border-radius;
            padding: 30px;
            @include flex($direction: column,$justifyContent: center);
            row-gap: 20px;
            z-index: 1;
        }

        &__wrapper-btn {
            @include flex($direction: column,$justifyContent: center);
            row-gap: 20px;
        }

        &__text {
            color: $color-white;
            font-size: toRem(18);
            line-height: toRem(25);
        }

        &__rs {
            @include flex($alignItems: center);
            column-gap: 10px;

            &__link {
                @include size(40px);
                min-height: 0;
                border-radius: $border-radius--round;
                background-color: $color-bg--transparent;
                margin: 0;
                padding: 0;
                border: none;

                svg {
                    fill: $color-white;
                    @include size(40px);
                }
          
                &:after {
                    background: $gradient-third border-box;
                    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: destination-out;
                    mask-composite: exclude;
                    border-radius: $border-radius--round;
                }

                &:before {
                    width: calc(100% + 2px);
                    opacity: 0;
                    background: $gradient-third;
                }

                &:hover, &:focus-within {
                    svg {
                        fill: $color-dark;
                    }

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        &__nl {
            width: 100%;
            border: none;
            background-color: $color-bg--transparent;
            margin: 0;
            padding: 0;
            color: $color-white;
          
            &:after {
                background: $gradient-third border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: destination-out;
                mask-composite: exclude;
            }

            &:before {
              background: $gradient-third;
            }

            &:hover, &:focus-within {
                color: $color-dark;
            }
        }

        .ff-stream {
            background: none!important;
            padding: 0!important;
            min-height: 0!important;
        }

        .ff-stream-wrapper {
            margin: 0!important;
            padding: 0!important;
        }

        .ff-header {
            display: none;
        }

        .ff-item {
            height: 370px!important;
            width: 370px!important; 
            background-color: $color-white;
            border-radius: $border-radius;
            overflow: hidden;
            box-shadow: $shadow;
            transform: none!important; 
            transition: $duration!important;

            &:hover {
                box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
            }

            &:nth-child(2) {
                transform: translateX(400px)!important;
            } 

            .ff-img-holder {
                border-radius: 15px!important;
                height: 200px;
    
                img {
                    min-height: 0!important;
                    object-fit: cover;
                    height: 100%;
                    object-position: top;
                }
            }

            .ff-item-cont {
                height: 330px;

                .ff-content {
                    height: 130px!important;
                    margin: 0!important;
                    padding: 25px;
                    box-sizing: border-box;
                }
            }

            .ff-item-meta {
                display: none;
            }

            .ff-item-bar {
                height: 40px!important;
            }
        }

        .picture-item__inner:hover:before {
            background: none;
        }

        .ff-loadmore-wrapper {
            display: none!important;
        }
    }

    //======================================================================================================
    // Annuaire
    //======================================================================================================
    .home-annuaire {
        > .container {
            @include flex($justifyContent: flex-end);
        }

        &__container {
            @include flex($direction: column, $justifyContent: space-between);
            @include size(800px,245px);
            background-color: $color-light;
            border-radius: $border-radius;
            padding: 30px;
            margin: 0 0 -65px;
        }

        &__title {
            font-size: toRem(35);
            line-height: toRem(45);
            column-gap: 20px;
            flex-direction: row;
            margin: 0;

            &:after {
                display: inline-block;
                width: 30px;
                background: $gradient-second;
            }
        }

        &__text {
            font-weight: $font-weight-bold;
            color: $color-text;
            font-size: toRem(18);
            line-height: toRem(25);
            margin: 0;

            span {
                font-weight: $font-weight;
            }
        }

        &__select {
            @include flex($alignItems: center);
            column-gap: 20px;
        }

        &__wrapper-select {
            @include flex($alignItems: center, $justifyContent: space-between);
            column-gap: 10px;
            flex: 1;
            border-radius: 10px; 
        }

        select  {
            appearance: none;
            z-index: 1;
            position: relative;
            @include flex($alignItems: center);
            width: 100%;
            flex: 1;
            height: 50px;
            background: url(/wp-content/themes/cc-adour-madiran/assets/src/images/sprite-images/pictos/ic_chevron_bas.svg) no-repeat center $color-light;
            background-position: calc(100% - 10px) 50%;
            border-color: $color-bg--transparent; 
            text-overflow : ellipsis;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: $font-size--text-small;
            line-height: toRem(20);
            letter-spacing: 1.4px;
            text-transform: uppercase;
            padding: 0 20px; 
            border-radius: 10px;
            border: 1px solid #36B152; 
            cursor: pointer;

            &::-ms-expand {
                display: none;
            }

            &:focus-within {
                border: 1px solid $color-text; 
            }
        }

        &__btn {
            position: relative;
            z-index: 1;
            background: $color-bg--transparent;
            border: none;
            @include size(55px,50px);
            border-radius: 10px;
            color: $color-text;
            text-transform: uppercase;
            font-weight: $font-weight-black;
            flex: none;
            margin: 0;
            padding: 0;
            transition: $duration;

            &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                @include size(100%);
                border-radius: 10px;
                transition: $duration; 
            }

            &:before {
                background: $gradient-second;
                opacity: 1;
                z-index: -2;
            }

            &:after {
                background: $color-dark;
                opacity: 0;
                z-index: -1;
            }

            &:hover, &:focus-within {
                background: $color-bg--transparent;
                color: $color-white;

                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        &__contact {
            @include flex($alignItems: center);
            column-gap: 20px;

            &__button {
                width: auto;
                flex: 1;
                margin: 0;
                padding: 0;
                background: $color-bg--transparent;
                z-index: 1;
                border: 1px solid $color-bg--transparent;

                &:after {
                    background: $gradient-second border-box;
                    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                    -webkit-mask-composite: destination-out;
                    mask-composite: exclude;
                }

                &:before {
                    background: $gradient-second;
                }

                &:hover, &:focus-within {
                    color: $color-dark;
                }
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        .newsthread {    
            &__title {
                &:before {
                    width: calc(((var(--vw, 1vw) * 100) - ($medium - $gl-gutter-md))/2 + 100%);
                }
            }
        }

        .access {
            padding: 85px 0 80px;

            &__list {
                column-gap: 20px;
            }

            &__wrapper {
                height: 200px;
            }

            &__text {
                font-size: toRem(14);
            }
        }

        .posts {    
            .swiper-slide {
                width: 300px;
                margin-right: 20px;
            }
    
            &__item {    
                &__container-img {
                    height: 220px;
                }
    
                &__title {
                    font-size: toRem(18);
                    line-height: torem(20);
                }
            }
        }

        .events {    
            &__list {
                column-gap: 20px;
            }
    
            &__link {
                @include size(220px,185px);
                padding: 30px 20px 45px;
    
                &__date {
                    right: 20px;
                    height: 50px;
                    font-size: $font-size--text-small;
                }
    
                &__title {
                    font-size: toRem(18);
                    line-height: toRem(20);
                }
    
                &__taxo {
                    font-size: $font-size;
                    line-height: toRem(20);
                }
            }
    
            &__template {
                column-gap: 20px;
                margin-top: 65px;
            }
        }

        .map {
            height: 660px;

            &__title {
                &:before {
                    right: -80px;
                }
            }

            &__text {
                margin-top: 30px;
                font-size: $font-size--text-small;
                line-height: toRem(20);
            }

            &__svg-wrapper {
                @include size(450px,500px);
                left: 210px;
            }

            &__list-numbers {
                margin-top: 50px;
                row-gap: 70px;
                padding: 0 15px;
            }

            &__number {
                &__count {
                    font-size: toRem(50);
                    line-height: toRem(50);
                }

                &__label {
                    font-size: toRem(22);
                    line-height: 2rem;
                }
            }

            &__content {
                margin-top: 60px;
                margin-right: 60px;
                max-width: 260px;
            }

            &__btn {
                width: 260px;
                margin: 45px 0 0;
            }
        }

        .follow {
            &__content {
                @include size(300px, 370px);
                padding: 30px 20px;
            }
    
            &__text {
                font-size: toRem(16);
            }
        
            .ff-item {
                width: 300px!important;
                height: 370px!important; 
    
                &:nth-child(2) {
                    transform: translateX(320px)!important;
                } 
            }
        }

        .home-annuaire {
            &__container {
                width: 655px;
                padding: 30px 20px;
                margin: 0 0 -85px;
            }
    
            &__text {
                font-size: toRem(16);
            }
    
            &__select {
                column-gap: 10px;
            }

            select  {
                padding: 0 20px 0 15px;
            }
    
            &__contact {
                column-gap: 10px;
            }
        }
    }    
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .newsthread {
            &__container {
                column-gap: 15px;
            }
    
            &__title {
                padding-right: 20px;
                font-size: toRem(16);
                line-height: toRem(20);
                flex: none;
    
                &:before {
                    width: calc(((var(--vw, 1vw) * 100) - ((var(--vw, 1vw) * 90) - $gl-gutter-sm))/2 + 100%);
                }
            }

            &__description {
                line-height: toRem(20);
            }
        }

        .access {            
            &__list {
                gap: 30px;
                flex-wrap: wrap;
                justify-content: center;
                max-width: 640px;
                margin: 40px auto 0;
            }

            &__wrapper {
                height: 250px;
                width: 100%;
                max-width: 170px;
                min-width: 140px;

                &:nth-child(1),
                &:nth-child(3){
                    margin-top: 40px;
                }
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6){
                    margin-top: 0;
                }
                &:nth-child(5){
                    margin-top: -40px;
                }
            }
        }

        .posts {    
            .swiper-slide {
                width: calc(50% - 10px);
            }
    
            &__item {      
                &__title {
                    font-size: toRem(18);
                    line-height: torem(20);
                }
            }
        }

        .events {
            &__list {
                flex-wrap: wrap;
                justify-content: center;
            }

            &__item {
                width: calc(50% - 10px);
                max-width: 300px;
                flex: auto;

                &:nth-child(2n) {
                    margin-bottom: 15px;
                }
            }

            &__link {
                @include size(100%, 170px);
            }

            &__template {
                margin-top: 50px;
            }
        }

        .map {
            height: auto;

            &__title {
                &:before {
                    right: auto;
                    left: 220px;
                }
            }
            &__svg-wrapper {
                display: none;
            }

            &__container {
                justify-content: flex-start;
                column-gap: 50px;
            }

            &__list-numbers {
                margin-top: 0;
                row-gap: 30px;
                padding: 0;
                width: 50%;
            }

            &__number {
                &__count {
                    font-size: toRem(35);
                    line-height: toRem(35);
                }

                &__label {
                    font-size: toRem(20);
                }
            }

            &__content {
                margin-top: 5px;
                margin-right: auto;
                width: 50%;
                max-width: 50%;
            }
        }

        .follow {
            &__container {
                flex-direction: column;
                row-gap: 20px;
            }

            &__content {
                width: 100%;
                height: auto;
                padding: 30px;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: wrap;
                column-gap: 50px;
            }

            &__wrapper-btn {
                gap: 20px;
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
            }

            &__rs {
                width: 100%;
            }

            &__text {
                width: 100%;
            }

            &__nl {
                width: 260px;
            }
        
            .ff-item {
                width: calc(50% - 10px)!important;
                height: 370px!important; 
    
                &:nth-child(2) {
                    transform: translateX(calc(100% + 20px))!important;
                } 
            }
        }

        .home-annuaire {
            &__container {
                width: 100%;
                padding: 30px 20px;
                margin: 0 0 -85px;
            }
    
            &__text {
                font-size: toRem(16);
            }

            &__contact {
                justify-content: space-between;

                &__button {
                    max-width: 260px;
                    flex: none;
                    padding: 0 10px;
                    border: 1px solid #36b152;

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }   
}


// 640
@media screen and (max-width: $small) {
    .home {
        &__title {
            font-size: toRem(35);
            line-height: toRem(40);

            &:before {
                width: 70px;
            }
        }

        .newsthread {
            height: auto;

            &__container {
                height: auto;
                min-height: 60px;
            }

            &__title {
                height: 60px;
                font-size: $font-size--text-small;
                column-gap: 5px;
                padding-right: 10px;
    
                &:before {
                    width: calc(((var(--vw, 1vw) * 100) - ((var(--vw, 1vw) * 90) - $gl-gutter-xs))/2 + 100%);
                }
            }
        }

        .access {      
            padding: 75px 0 60px;

            &__list {
                gap: 20px;
                max-width: 360px;
                margin: 70px auto 0;
            }

            &__wrapper {
                height: 200px;
                min-width: 130px;

                &:nth-child(1),
                &:nth-child(3),
                &:nth-child(5){
                    margin-top: 0;
                }
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6){
                    margin-top: -40px;
                }
            }
        }

        .posts {    
            padding-top: 70px;

            .swiper-slide {
                width: 100%;
            }
    
            &__item {      
                &__title {
                    font-size: toRem(18);
                    line-height: torem(20);
                }
            }

            &__nav {
                position: relative;
                top: 0;
                right: auto;
                left: auto;
                margin: 5px auto 0;
            }

            &__template {
                margin-top: 30px;
            }
        }

        .events {
            padding: 60px 0;

            &__list {
                row-gap: 45px;
            }

            &__item {
                width: 100%;
                max-width: 100%;
                flex: auto;

                &:nth-child(2n) {
                    margin-bottom: 0;
                    margin-top: 0;
                }
            }

            &__template {
                flex-direction: column;
                align-items: center;
                row-gap: 20px;

                &__prop,
                &__all {
                    width: 100%;
                    max-width: 270px;
                }
            }
        }

        .map {
            padding: 90px 0 60px;

            &__title {
                &:before {
                    left: 160px;
                }
            }

            &__container {
                flex-direction: column-reverse;
                row-gap: 40px;
            }

            &__btn {
                max-width: 400px;
                width: 100%;
                margin-right: 40px;
            }

            &__content {
                margin-top: 0;
                max-width: 400px;
                width: 100%;
            }

            &__list-numbers {
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            &__number {
                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 3;
                }
                &:nth-child(3) {
                    order: 2;
                }

                &__count {
                    font-size: toRem(25);
                    line-height: toRem(25);
                }

                &__label {
                    font-size: toRem(16);
                    line-height: toRem(20);
                }
            }
        }

        .follow {
            padding: 60px 0;
            
            &__content {
                flex-wrap: wrap;
                padding: 20px;
            }

            &__wrapper-btn {
                width: 100%;
            }

            &__text {
                width: 100%;
            }

            .ff-item {
                width: 100%!important;
                height: 350px!important;

                &:nth-child(2) {
                    transform: translateY(calc(100% + 20px))!important;
                }
    
                .ff-item-cont {
                    height: 310px;
    
                    .ff-content {
                        height: 110px!important;
                        padding: 20px;
                    }
                }
            }
        }

        .home-annuaire {
            &__title {
                font-size: toRem(30);
                line-height: toRem(35);
                column-gap: 10px;

                &:after {
                    width: 20px;
                }
            }

            &__container {
                height: auto;
                min-height: 305px;
                row-gap: 20px;
            }

            &__select,
            &__contact {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 10px;
            }

            &__wrapper-select {
                width: 100%;
            }
        }
    }
}
