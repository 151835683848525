.form-dropdown {
    position: relative;
    @include flex($alignItems: center, $justifyContent: center);
    width: calc(100% - 200px);
    margin: 0 auto;

    label {
        display: none;
    }

    .dropdown-submit {
        position: relative;
        @include size(65px, 70px);
        margin: 0 0 0 -3px;
        padding: 0;
        background-color: $color-dark;
        border-width: $border-width;
        border-radius: 0 $border-radius $border-radius 0;
        color: $color-white;

        &:hover, &:focus {
            background-color: $color-light;
            border-color: $color-gray;
            color: $color-text;
        }
    }

    .select2, .list-select2 {
        position: relative;
        box-sizing: border-box;
        @include flex($alignItems: center, $justifyContent: space-between);
        height: 70px;
        background-color: $color-white;
        border: 1px solid $color-gray;
        border-radius: $border-radius;
        flex: 1;

        &-container--open {
            border-color: $color-bg--transparent;
            box-shadow: $shadow;
        }

        &.select2-container--open {
            &:after {
                transform: rotate(-180deg);
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 40px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;    
            background: $color-light url($urlShort + 'ic-dropdown-chevron.svg') no-repeat 50%;
            border-radius: $border-radius--round; 
            transform: rotate(0);
            transition: transform $duration $timing;
        }

        .selection {
            height: 100%;
            width: 100%;
        }

        &-selection {
            height: 100%!important;
            border: none!important;
            display: flex!important;
            align-items: center;
            z-index: 1;
            position: relative;
            background-color: $color-bg--transparent!important;

            &__rendered {
                width: 100%;
                padding: 5px 70px 5px 20px!important;
            }

            &__placeholder {
                color: $color-text;
            }

            &__arrow {
                display: none;
            }
        }
    }
}


.select2 {
    &-dropdown {
        background-color: $color-light!important;
        box-shadow: $shadow;
    }

    &-search {
        &--dropdown {
            position: relative;
            padding: 0!important;
            margin: 25px 20px 0;
        
            &::after {
                content: '';
                @include absolute($right: 5px, $top: 0, $bottom: 0);
                @include flex($alignItems: center, $justifyContent: center);
                width: $search-input-button-width;
                height: $search-input-button-height;
                margin: auto;
                cursor: pointer;
                background: $search-input-button-bg url($urlShort + 'ic-misc-search.svg') no-repeat 50%;
                border: $search-input-button-border;
                transition: {
                    property: background-image, background-color, border-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                &::after {
                    background: url($urlShort + 'ic-misc-search-hover.svg') no-repeat center $search-input-button-bg--active;
                    border-color: $search-input-button-border-color--focus;
                }
            }

            .select2-search__field {
                height: 60px;
                background-color: $search-input-bg;
                border-color: $search-input-border-color;
                padding: 0 60px 0 15px!important;

                &:focus {
                    border-color: $search-input-border-color--active;
                }
            }

        }
    }

    &-results {
        background-color: $color-light;
        padding: 20px 20px 10px;

        &__option {
            display: flex;
            align-items: center;
            min-height: 40px;
            margin-bottom: 10px;
            padding: 5px 15px!important;
            background-color: $color-white;
            border-radius: $border-radius;
            transition: {
                property: background-color, color;
                duration: $duration;
                timing-function: $timing;
            }
        
            &:hover, &:focus {
                background-color: $color-dark;
                color: $color-white;
            }

            &--highlighted {
                background-color: $color-dark!important;
                color: $color-white!important;
            }

            &.select2-results__message {
                background-color: $color-white;
                color: $color-text;
            }
        }
    }

    &-container {
        &--open {
            .select2-dropdown--above {
                border: none;
            }
        }

        .select2-search--dropdown {
            .select2-search__field {
                &:focus {
                    border-color: $color-dark;
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {
    .form-dropdown {
        width: calc(100% - 100px);
    }
}


// 640
@media screen and (max-width: $small) {
    .form-dropdown {
        width: 100%;
    }
}
